import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faWrench, faPaintBrush, faArrowLeft, faChevronDown, faRightFromBracket, faRotate, faBolt, faBug, faCloudUpload } from "@fortawesome/free-solid-svg-icons"
import styles from "./ConfigurationTopBar.module.css"
import { LoginState } from "../redux/login"
import clsx from "clsx"
import React, { useState } from "react"
import { createPortal } from "react-dom"
import "@fontsource-variable/exo-2"
import Command from "../helpers/Command"
import { logout } from "../api/login"
import { Trigger, TriggerPopup } from './TriggerPopup'
import { store, useSelector } from "../store"
import { setDebuggerEnabled } from "../redux/debugging"
import {Tooltip} from '@mui/material'

export function ConfigurationTopBar(props: {
  isPlatform: boolean
  loginState: LoginState
  projectPath: string
  viewerPath: string
  repoURL: string
  openAppSettings: () => void
  mode: "edit" | "preview"
  saveTriggers: (triggers: Trigger[]) => Promise<void>
  triggers: Trigger[]
}) {
  const [ triggerPopupOpen, setTriggerPopupOpen ] = useState(false)
  const enabledDebugger = useSelector(s => s.debugging.enabled)

  const logoutApp = () => {
    Command.sendCommand({
      props: { repoURL: props.repoURL }
    }, 'logout', null, function () {
      window.location.reload()
    })
  }

  const logoutPlatform = async () => {
    await logout()
    window.location.reload()
  }

  if (!props.loginState.isLoggedIn || props.loginState.user.role === "viewer") {
    return null
  }

  return (
    <>
      {triggerPopupOpen && createPortal(
        <TriggerPopup
          open
          onClose={() => setTriggerPopupOpen(false)}
          triggers={props.triggers}
          saveTriggers={props.saveTriggers}
        />,
        document.body)}

      <TopBar small>
        <Item to={`/projects/${props.projectPath}`}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="back-button"
          />
        </Item>
        <Item
          active={props.mode === 'edit'}
          to={`/projects/${props.projectPath}/${props.viewerPath}/${props.mode === "edit" ? "" : "edit"}${window.location.search}`}>
          <FontAwesomeIcon icon={faPaintBrush} />
          <IconText>Edit widgets</IconText>
        </Item>
        <Item onClick={props.openAppSettings}>
          <FontAwesomeIcon icon={faWrench} className={"iets"} />
          <IconText>App settings</IconText>
        </Item>
        <Item onClick={() => setTriggerPopupOpen(true)}>
          <FontAwesomeIcon icon={faBolt} />
          <IconText>Triggers</IconText>
        </Item>
        <Tooltip title={
          <span>
            Enables rule engine logging in developer console. <br />
            This may impact performance and increase server resource usage.
          </span>
        }>
          <Item
            active={enabledDebugger}
            onClick={() => setDebuggerEnabled(!enabledDebugger)}>
            <FontAwesomeIcon icon={faBug} />
            <IconText>Debug rules</IconText>
          </Item>
        </Tooltip>
        <Tooltip title={
          <span>
            Lock&Publish the app to an existing URL. Not automated yet.
          </span>
        }>
          <Item
            onClick={() => {window.open("https://bim-connected.slite.com/app/docs/-1nAHhv2p9KvWC/Lock-and-Publish-guidelines")}}>
            <FontAwesomeIcon icon={faCloudUpload} />
            <IconText>Lock&Publish</IconText>
          </Item>
        </Tooltip>

        <Separator />

        <Item chevron>
          <span>{props.loginState.user.name}</span>
          <FontAwesomeIcon icon={faUser} style={{ marginBottom: '2px' }} />
          <SubMenu>
            <Item onClick={() => logoutApp()}>
              <FontAwesomeIcon icon={faRotate} />
              <IconText>Log out / reset app session</IconText>
            </Item>
            <Item onClick={() => logoutPlatform()}>
              <FontAwesomeIcon icon={faRightFromBracket} />
              <IconText>Log out platform</IconText>
            </Item>
          </SubMenu>
        </Item>
      </TopBar>
    </>
  )
}

function TopBar(props: {
  children: React.ReactNode,
  small?: boolean
}) {
  return <ul className={clsx(styles["top-bar"], "flex items-center", {
    [styles["small"]]: !!props.small
  })}>{props.children}</ul>
}

function SubMenu(props: {
  children: React.ReactNode
}) {
  return <ul className={styles["sub-menu"]}>{props.children}</ul>
}

function Separator() {
  return <li className={styles["separator"]} />
}

function IconText(props: {
  children: React.ReactNode
}) {
  return <span className={styles["icon-text"]}>{props.children}</span>
}

const Item = React.forwardRef<HTMLLIElement, {
  to?: string | null
  onClick?: () => void
  children: React.ReactNode
  active?: boolean
  chevron?: boolean
}>((props, ref) => {
  const { to, onClick, children, active, chevron, ...otherProps } = props
  const isClickable = !!props.to || !!props.onClick
  return (
    <li ref={ref} {...otherProps} className={clsx(styles["item"], {
      [styles["clickable"]]: isClickable,
      [styles["active"]]: !!props.active
    })}>
      <Link to={props.to ?? "#"} onClick={e => {
        if (!props.onClick) return
        e.preventDefault()
        props.onClick()
      }}>
        {props.children}
        {props.chevron && (
          <FontAwesomeIcon className={styles.chevron} icon={faChevronDown} />
        )}
      </Link>
    </li>
  )
})
