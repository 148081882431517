import clsx from 'clsx'
import { Dialog, DialogContent } from '@mui/material'
import { faRemove, faAdd, faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import style from './TriggerPopup.module.css'
import formStyle from './basic/Form.module.css'
import inputStyle from './basic/Input.module.css'
import { Button } from './basic/Button'
import { useRef, useState } from "react"
import { v4 as uuidv4 } from 'uuid'
import { SparqlEditor } from './SPARQLEditor'

export type Draft = {
  triggers: Trigger[]
  triggerIdx: number
}

export type Trigger = {
  id: string
  label: string
  sparqlQuery: string
  voteForUserActionsDisabledWhenLoading: boolean
}

const DEFAULT_TRIGGER_DRAFT = createDefaultTriggerDraft()

export function TriggerPopup(props: {
  triggers: Trigger[]
  open: boolean
  onClose: () => void
  saveTriggers: (triggers: Trigger[]) => Promise<void>
}) {
  const [draft, setDraft] = useState<Draft>({
    triggers: props.triggers.length ? props.triggers : [DEFAULT_TRIGGER_DRAFT],
    triggerIdx: 0, })

  const labelInputRef = useRef<HTMLInputElement>(null)

  const currentTriggerDraft = draft.triggers[draft.triggerIdx]
  if (!currentTriggerDraft) throw new Error(`current trigger draft does not exist (index ${draft.triggerIdx})`)

  const selectTrigger = (triggerIdx: number) => setDraft(d => ({ ...d, triggerIdx }))

  const addNewTrigger = () => setDraft(d => ({
      triggers: [...d.triggers, createDefaultTriggerDraft()],
      triggerIdx: draft.triggers.length }))

  const editTrigger = (triggerUpdate: Partial<Trigger>) => setDraft(d => ({ ...d,
    triggers: d.triggers.map((trigger, i) => i === d.triggerIdx ? {...trigger, ...triggerUpdate} : trigger) }))

  const removeTrigger = (removeIdx: number) => setDraft(d => {
    if (d.triggers.length === 1) return { triggers: [DEFAULT_TRIGGER_DRAFT], triggerIdx: 0 }
    return {
      triggers: d.triggers.filter((_, i) => i !== removeIdx),
      triggerIdx: (() => {
         if (removeIdx === 0 && d.triggerIdx === 0) return 0
         if (removeIdx <= d.triggerIdx) return d.triggerIdx - 1
         return d.triggerIdx
      })()}
  })

  return (
    <Dialog open={props.open} fullWidth maxWidth="md" onClose={() => {}}>
      <DialogContent style={{ padding: '0' }}>
        <div className={style["popup-content"]}>

          <nav className={style.sidebar}>

            <div className={style["section-header"]}>
              <span>Triggers</span>
              <div className={style["tool-bar"]}>
                <button type="button" onClick={() => {
                  addNewTrigger()
                  requestAnimationFrame(() => {
                    labelInputRef.current?.focus()
                    labelInputRef.current?.select()
                  })
                } }>
                  <FontAwesomeIcon icon={faAdd} />
                  <span>Add</span>
                </button>
              </div>
            </div>

            <div className={style.items}>
              {draft.triggers.map((trigger, i) => (
                <button
                  key={trigger.id}
                  className={clsx(style.item, style["on-hover"], { [style.active]: i === draft.triggerIdx })}
                  onClick={() => selectTrigger(i)}
                >
                  {i === draft.triggerIdx && (<FontAwesomeIcon icon={faEdit} />)}
                  <span>{trigger.label}</span>
                  <div className={clsx(style["tool-bar"])}>
                    <button type="button" title="Remove" className={style["square"]}>
                      <FontAwesomeIcon icon={faRemove} onClick={e => {
                        e.stopPropagation()
                        removeTrigger(i)
                      }} />
                    </button>
                  </div>
                </button>
              ))}
            </div>

          </nav>
          <form className={clsx(style.content, formStyle.form)} onSubmit={(e)=> {e.preventDefault()}}>

            <div className={style['form-content']}>
              <label className={formStyle['form-field']}>
                <span>Label</span>
                <input type="text" ref={labelInputRef} required className={inputStyle.input} value={currentTriggerDraft.label}
                  onChange={e => editTrigger({label: e.target.value})}
                />
              </label>

              <label className={formStyle['form-field']}>
                <span>SPARQL query</span>
                <SparqlEditor
                  mode="update-value"
                  value={currentTriggerDraft.sparqlQuery}
                  onBlur={(v: string) => editTrigger({sparqlQuery: v})}
                  id='yasqe-trigger-popup' />
              </label>

              <label className={clsx(formStyle['form-field'], formStyle.checkbox)}>
                <input type="checkbox"
                  checked={currentTriggerDraft.voteForUserActionsDisabledWhenLoading}
                  onChange={() => editTrigger({
                    voteForUserActionsDisabledWhenLoading: !currentTriggerDraft.voteForUserActionsDisabledWhenLoading})}
                />
                <span>Block User Interaction</span>
              </label>
            </div>

            <div className={style['footer']}>
              <Button variant="outline" onClick={props.onClose}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" onClick={async () => {
                await props.saveTriggers(draft.triggers)
                props.onClose()
              }}>
                Save
              </Button>
            </div>

          </form>

        </div>
      </DialogContent>
    </Dialog>
  )
}

function createDefaultTriggerDraft() {
  return {
    id: uuidv4(),
    label: 'Untitled trigger',
    sparqlQuery: '',
    voteForUserActionsDisabledWhenLoading: false
  }
}
