import React from 'react'
import { SparqlEditor } from './SPARQLEditor'
import { Input, Label } from 'reactstrap'
import { FormGroup} from './FormGroup'
import AceEditor from 'react-ace'
// import MonacoEditor from 'react-monaco-editor';
// import brace from 'brace';

import styles from './FormField.module.css'
import { ShowableComponent, useDialog } from "../hooks/use-dialog"
import { HelpDialog } from "./HelpDialog"
import { JsonEditor, JsonTextEditor } from "./JsonEditor"
import {mergeClasses} from '../helpers/styling'
import cssFix from './mui/resolutions.module.css'
import { SearchSelect } from './ui-primitives/search-select'
import {TablePPCustomConfigView} from './widgets/TablePlusPlus/components/CustomConfiguration'
import {type TablePlusPlusCustomConfig, DEFAULT_TABLEPP_CUSTOM_CONFIG} from './widgets/TablePlusPlus/config-schema'

type FormFieldProps = {
    name: string
    label: React.ReactNode
    topicValueInterpolationEnabled?: boolean
    placeholder?: string
    noResultsLabel?: string
    helpComponent?: ShowableComponent<{}, void>}
  & {setConfigFeedback?: SetConfigFeedback}
  & ( {type: 'array', value: string[], onChange: (name: string, value: string[]) => void}
    | {type: 'boolean', value: boolean, onChange: FormFieldOnChange<boolean>}
    | {type: 'javascript', value: string, onChange: (name: string, value: string) => void}
    | {type: 'json', value: string, onChange: (name: string, value: string) => void}
    | {type: 'json-text', value: string, onChange: (name: string, value: string) => void}
    | {type: 'number', value: string, onChange: (name: string, value: string) => void}
    | {type: 'select', value: string, onChange: (name: string, value: string) => void, options: {label: string, value: string}[]}
    | {type: 'searchselect', value: string, onChange: (name: string, value: string) => void, options: {label: string, value: string}[]}
    | {type: 'text', value: string, onChange: (name: string, value: string) => void}
    | {type: 'yasgui', value: string, onChange: (name: string, value: string) => void}
    | {type: 'tablepp-custom', value?: TablePlusPlusCustomConfig,
       onChange: (name: string, value: TablePlusPlusCustomConfig) => void
       setConfigFeedback: SetConfigFeedback}
    )

type FormFieldOnChange<V, O = void> = (name: string, value: V) => O

export type SetConfigFeedback = (type: 'valid' | 'warning' | 'error', message?: React.ReactNode) => void

function FormField(props: FormFieldProps): React.ReactNode {
  switch (props.type) {

    case 'yasgui': return (
      <FormGroup>
        <LabelWithHelp label={props.label} helpComponent={props.helpComponent} />
        <SparqlEditor
          mode="init-value"
          value={props.value}
          onBlur={(value) => props.onChange(props.name, value)}
          id='yasqe-widget-main-query'
        />
      </FormGroup>
    )

    case 'javascript': return (
      <FormGroup>
        <LabelWithHelp label={props.label} helpComponent={props.helpComponent} />
        <AceEditor
          mode="javascript"
          theme="github"
          style={{ width: '100%', height: '120px' }}
          value={props.value}
          onChange={(newValue) => {
            props.onChange(props.name, newValue)
          }}
          name="editor"
        />
      </FormGroup>
    )

    case 'select': return (
      <FormGroup>
        <LabelWithHelp label={props.label} helpComponent={props.helpComponent} />
        <Input
          className={mergeClasses(styles.default, 'dark-form-control', cssFix['dark-form-control-select'])}
          value={props.value || ''}
          type="select"
          onChange={e => props.onChange(props.name, e.target.value)}
        >
          {props.options && props.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>))}
        </Input>
      </FormGroup>
    )

    case 'searchselect': return (
      <FormGroup>
        <LabelWithHelp label={props.label} helpComponent={props.helpComponent} />
        <SearchSelect
          value={props.value || ''}
          onChange={value => props.onChange(props.name, value)}
          options={props.options}
          placeholder={props.placeholder || 'Search...'}
          noResultsLabel={props.noResultsLabel || 'No results found'}
          buttonClassName="legacy bg-[#373a3c] !border-[#808080]"
          contentClassName="legacy"
        />
      </FormGroup>
    )

    case 'boolean': return (
      <FormGroup>
        <Label style={{ cursor: 'pointer' }}>
          <Input
            checked={props.value ?? false}
            className={styles.check}
            type="checkbox"
            onChange={(e) =>
              props.onChange(props.name, e.target.checked)
            }
          />
          {'  '}
          {props.label}
        </Label>
      </FormGroup>
    )

   case 'json': return (
      <FormGroup>
        <LabelWithHelp label={props.label} helpComponent={props.helpComponent} />
        <JsonEditor name={props.name}
                    value={props.value}
                    onValueChange={value => {
                      props.onChange(props.name, value as string)
                    }} />
      </FormGroup>
    )

    case 'json-text': return (
      <FormGroup>
        <LabelWithHelp label={props.label} helpComponent={props.helpComponent} />
        <JsonTextEditor name={props.name} value={props.value || ''}
                        onValueChange={value => {
                          props.onChange(props.name, value as string)
                        }} />
      </FormGroup>
    )

    case 'array': return (
      <FormGroup>
        <LabelWithHelp label={props.label} helpComponent={props.helpComponent}
          topicValueInterpolationEnabled={props.topicValueInterpolationEnabled} />
        <Input className="dark-form-control" value={props.value || ''}
          onChange={e => props.onChange(props.name, e.target.value.split(','))}
        />
      </FormGroup>
    )

    case 'tablepp-custom': return (
      <FormGroup>
        <LabelWithHelp label={props.label} helpComponent={props.helpComponent}
          topicValueInterpolationEnabled={props.topicValueInterpolationEnabled} />
        <TablePPCustomConfigView name={props.name}
          customConfig={props.value ?? DEFAULT_TABLEPP_CUSTOM_CONFIG}
          setConfigFeedback={props.setConfigFeedback}
          onChange={props.onChange} />
      </FormGroup>
    )

    case 'text': return (
      <FormGroup>
        <LabelWithHelp label={props.label} helpComponent={props.helpComponent}
          topicValueInterpolationEnabled={props.topicValueInterpolationEnabled} />
        <Input className="dark-form-control" value={props.value || ''}
          onChange={e => props.onChange(props.name, e.target.value)}
        />
      </FormGroup>
    )

    case 'number': return (
      <FormGroup>
        <LabelWithHelp label={props.label} helpComponent={props.helpComponent}
          topicValueInterpolationEnabled={props.topicValueInterpolationEnabled} />
        <Input className="dark-form-control" value={props.value || ''}
          onChange={e => props.onChange(props.name, e.target.value)}
        />
      </FormGroup>
    )

    default:
      const anyProps: {[Property in keyof FormFieldProps]-?: any} = props
      console.error(`unknown type of formfield: ${anyProps.type}`)
      return (
        <FormGroup>
          <LabelWithHelp label={anyProps.label} helpComponent={anyProps.helpComponent}
            topicValueInterpolationEnabled={anyProps.topicValueInterpolationEnabled} />
          <Input className="dark-form-control"
            onChange={e => anyProps.onChange(anyProps.name, e.target.value)} />
        </FormGroup>
      )
  }
}

function LabelWithHelp(
  props: Pick<FormFieldProps, 'label' | 'topicValueInterpolationEnabled' | 'helpComponent'>
) {
  const show = useDialog()
  const helpComponent = props.helpComponent

  return (
    <Label style={{ width: '100%', whiteSpace: 'pre-line' }}>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div style={{ flexGrow: 1 }}>{props.label}</div>

        {props.topicValueInterpolationEnabled &&(
          <i>topic {"{{variables}}"} enabled</i>
        )}
        {helpComponent && (
          <a href="#" onClick={e => {
            e.preventDefault()
            show(HelpDialog, {component: helpComponent})
          }}>
            Help...
          </a>
        )}
      </div>
    </Label>
  )
}

export default FormField
