import Yasqe from '@triply/yasqe'

export type CurrentViewerState = {
  appCss: Record<string, string | number>
  activeYasqes: Record<string, Yasqe>
  votersForGlobalUserActionsDisabled: Set<string>
  config?: Record<string, unknown> | null
}

const initialState: CurrentViewerState = {
  appCss: {},
  activeYasqes: {},
  votersForGlobalUserActionsDisabled: new Set<string>(),
}

type Action
  = {type: "VOTE_USER_ACTIONS_DISABLED", data: {value: boolean, voterId: string}}
  | {type: "SET_VIEWER_CONFIG", data: Record<string, unknown>}
  | {type: "SET_APP_CSS", data?: Record<string,string>}
  | {type: "ADD_YASQE", data: Yasqe}
  | {type: "REMOVE_YASQE", data: string}

export default function currentViewerReducer(state: CurrentViewerState = initialState, action: Action): CurrentViewerState {
  switch (action.type) {
    case "ADD_YASQE": {
      const { id, yasqe } = action.data
      return {
        ...state,
        activeYasqes: {
          ...state.activeYasqes,
          [id]: yasqe
        }
      }
    }
    case 'REMOVE_YASQE': {
      const newActiveYasqes = { ...state.activeYasqes }
      delete newActiveYasqes[action.data]
      return { ...state, activeYasqes: newActiveYasqes }
    }
    case "SET_APP_CSS":
      return {...state, appCss: action.data ?? {}}
    case "SET_VIEWER_CONFIG":
      return {...state, config: action.data }
    case "VOTE_USER_ACTIONS_DISABLED":
      const newVoters = new Set(state.votersForGlobalUserActionsDisabled)
      if (action.data.value)
        newVoters.add(action.data.voterId)
      else
        newVoters.delete(action.data.voterId)

      return { ...state, votersForGlobalUserActionsDisabled: newVoters }
  }

  return state
}

export function isUserActionsDisabled(state: CurrentViewerState) {
  return state.votersForGlobalUserActionsDisabled.size > 0
}
